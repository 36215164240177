import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import {
  fetchAllOrders,
  fetchAccountTypes,
  setExternalOrderToEdit,
  clearOrderDetails,
  getReceptionOrders,
  fetchOrderTypes,
  fetchAllOrderStatuses,
  processReceptionOrder,
  clearReceptionOrders,
  printWhiteReceipt,
  sendListDelaySMS,
  clearReadyInLockerSMS,
  getOrderByPackageOrNumber,
  clearOrderByPackageOrNumber,
  sendEndOfDayNotification,
  clearEndOfDay,
  clearNotAllowedToProcess,
  getAllRouteAreaCodes,
} from "../../../../../actions"
import { Pagination, BlueButton } from "../../../../common"
import YesNoPopup from "../../../../Modals/YesNoPopup/yesNoPopup.js"
import { getStatusNameFromObj, getValEnumFromIndex } from "../../../../../utils"
import ReceptionOrdersList from "./ReceptionOrdersList"
import NotificationModal from "../../../../Modals/NotificationModal"
import Select from "react-select"
import "./style.scss"

const GeneralReceptionPage = (props) => {
  const [orderForEdit, setOrderForEdit] = useState(null)
  const [ordersWithStatuses, setOrdersWithStatuses] = useState(null)
  const [pagedList, setPagedList] = useState([])
  const [pageNum, setPageNum] = useState(1)
  const [ordersCount, setOrdersCount] = useState(0)
  const [acceptedOrder, setAcceptedOrder] = useState(null)
  const [isUpdateErrorMsg, setIsUpdateErrorMsg] = useState(false)
  const [isNotFoundMsg, setNotFoundMsg] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [isFadeVisible, setIsFadeVisible] = useState(true)
  const [isDuplicateMsg, setDuplicateMsg] = useState(false)
  const [isCheapSimMsg, setCheapSimMsg] = useState(false)
  const [isMultipleBagsMsg, setMultipleBagsMsg] = useState(false)
  const [isSendSmsConfirmMsg, setSendSmsConfirmMsg] = useState(false)
  const [isSendSmsDelaySuccessMsg, setSendSmsDelaySuccessMsg] = useState(false)
  const [isNotFoundMsgWithSearch, setIsNotFoundMsgWithSearch] = useState(false)
  const [isShowGameStorm, setIsShowGameStorm] = useState(true)
  const [isEndOfDayConfirmMsg, setIsEndOfDayConfirmMsg] = useState(false)
  const [isShowNotes, setIsShowNotes] = useState(false)
  const [notesList, setNotesList] = useState(false)
  const [formatedRouteAreacCodes, setFormatedRouteAreacCodes] = useState(null)
  const [selectedAreaCode, setSelectedAreaCode] = useState(null)
  const orderPackageRef = useRef(null)
  const selectRef = useRef(null)
  const [isSelectFocused, setIsSelectFocused] = useState(false)
  const [currentOrderAreaCode, setCurrentOrderAreaCode] = useState(null)
  const [isShowRouteCodeValidation, setIsShowRouteCodeValidation] =
    useState(false)
  const accountTypesRecieveNotForPrint = [10, 12, 16, 20, 22, 25, 29, 31]
  const accountTypesReturnsNotForPrint = [9, 10, 12, 16, 20, 22, 31]

  useEffect(() => {
    props.getAllRouteAreaCodes()
    props.getReceptionOrders()
    return () => {
      clearState()
    }
  }, [])

  useEffect(() => {
    const handleGlobalClick = (event) => {
      if (
        orderPackageRef.current &&
        !orderPackageRef.current.contains(event.target) &&
        !isSelectFocused
      ) {
        orderPackageRef.current.focus()
      }
    }

    document.addEventListener("click", handleGlobalClick)

    return () => {
      document.removeEventListener("click", handleGlobalClick)
    }
  }, [isSelectFocused])

  useEffect(() => {
    if (selectedAreaCode && orderPackageRef.current) {
      orderPackageRef.current.disabled = false
      orderPackageRef.current.focus()
    }
  }, [selectedAreaCode])

  useEffect(() => {
    if (props.isOrderByPackageOrNumberSuccess) {
      if (props.orderByPackageOrNumber.orderType == 1) {
        setIsNotFoundMsgWithSearch(true)
        return
      }
      setNotFoundMsg(true)
    }
    if (props.isOrderByPackageOrNumberFail) {
      setNotFoundMsg(true)
      return
    }
  }, [
    props.isOrderByPackageOrNumberSuccess,
    props.isOrderByPackageOrNumberFail,
  ])

  useEffect(() => {
    if (props.sendSmsSuccess) {
      setSendSmsDelaySuccessMsg(true)
    }
  }, [props.sendSmsSuccess])

  useEffect(() => {
    if (props.receptionOrdersList) {
      props.fetchOrderTypes()
      props.fetchAllOrderStatuses()
      props.fetchAccountTypes()
    }
  }, [props.receptionOrdersList])

  useEffect(() => {
    if (
      props.orderTypes?.length > 0 &&
      props.accountTypes &&
      props.receptionOrdersList?.length > 0
    ) {
      props.receptionOrdersList.map((element) => {
        element.orderFormatStatus = getStatusNameFromObj(
          props.orderStatusesObj,
          element.orderStatus
        )
        element.orderFromatType = getValEnumFromIndex(
          props.orderTypes,
          element.orderType
        )
        element.orderFromatAccount = getValEnumFromIndex(
          props.accountTypes,
          element.accountType
        )
      })
      if (props.logedUserData?.role === "WHOperator") {
        const filteredOrders = props.receptionOrdersList.filter(
          (order) => order.orderType !== 1 && order.accountType !== 14
        )
        setOrdersWithStatuses(filteredOrders)
      } else {
        setOrdersWithStatuses(props.receptionOrdersList)
      }
    }
  }, [props.orderTypes, props.accountTypes, props.receptionOrdersList])

  useEffect(() => {
    if (props.updatedReceptionOrder && props.receptionOrderProcessSuccess) {
      setSearchTerm("")
      setIsFadeVisible(true)
      setOrdersCount(ordersCount + 1)
      setAcceptedOrder(props.updatedReceptionOrder)
      //removeOrderFromList(orderForEdit)
      if (
        (props.updatedReceptionOrder.orderStatus === 3 &&
          orderForEdit.orderType != 1 &&
          accountTypesRecieveNotForPrint.includes(orderForEdit?.accountType) ==
            false) ||
        (props.updatedReceptionOrder.orderStatus === 16 &&
          accountTypesReturnsNotForPrint.includes(orderForEdit?.accountType) ==
            false)
      ) {
        props.printWhiteReceipt(props.updatedReceptionOrder)
      }
      if (
        props.updatedReceptionOrder.orderType == 1 &&
        props.updatedReceptionOrder.orderStatus == 1
      ) {
        window.open(
          window.location.origin +
            "/OrderEditExternalScreen?orderId=" +
            orderForEdit?.orderId,
          "_blank"
        )
      }
    }
  }, [props.receptionOrderProcessSuccess])

  useEffect(() => {
    if (props.receptionOrderProcessFail == true) {
      setIsUpdateErrorMsg(true)
    }
  }, [props.receptionOrderProcessFail])

  useEffect(() => {
    if (searchTerm) {
      setIsFadeVisible(false)
      const delayDebounce = setTimeout(() => {
        let order = serchForOrder(searchTerm)
        if (!order) {
          props.getOrderByPackageOrNumber(searchTerm)

          return
        }
        setOrderForEdit(order)
        if (
          selectedAreaCode?.value != "-1" &&
          order?.orderType == 12 &&
          order?.routeAreaCode != selectedAreaCode?.label
        ) {
          setCurrentOrderAreaCode(order?.routeAreaCode)
          setIsShowRouteCodeValidation(true)
          return
        }

        if (order == "duplicate") {
          setDuplicateMsg(true)
          return
        }
        if (order == "cheapsim") {
          setCheapSimMsg(true)
          return
        }
        if (order == "multipleBags") {
          setMultipleBagsMsg(true)
          return
        }
        props.processReceptionOrder(order.orderId)
      }, 1000)
      return () => clearTimeout(delayDebounce)
    }
  }, [searchTerm])

  useEffect(() => {
    if (acceptedOrder) {
      const notes = acceptedOrder.orderItems
        .filter((item) => item.notes)
        .map((item, index) => <li key={index}>{item.notes}</li>)
      if (notes.length > 0) {
        setNotesList(<ul className="reception-notes-list">{notes}</ul>)
        setIsShowNotes(true)
      }
    }
  }, [acceptedOrder])

  useEffect(() => {
    if (props.routeAreaCodes?.length > 0) {
      const formatedRouteAreaCodes = formatRouteAreaCodesObject(
        props.routeAreaCodes
      )
      setFormatedRouteAreacCodes(formatedRouteAreaCodes)
    }
  }, [props.routeAreaCodes])

  const setGameStorm = (isShow) => {
    setIsShowGameStorm(isShow)
    if (!isShow) {
      setOrdersWithStatuses(
        ordersWithStatuses.filter((order) => order.accountType != 22)
      )
    } else {
      props.getReceptionOrders()
    }
  }

  const formatRouteAreaCodesObject = (arrayOfObjects) => {
    const options = arrayOfObjects.map((obj) => ({
      label: obj.routeAreaCodeName,
      value: obj.id,
      routeId: obj.routeId,
    }))

    options.unshift({
      label: "ללא קוד איזור",
      value: "-1",
      routeId: null,
    })

    return options
  }

  const clearState = () => {
    props.clearReadyInLockerSMS()
    props.clearOrderDetails()
    props.clearReceptionOrders()
    props.clearOrderByPackageOrNumber()
    setOrdersWithStatuses(null)
  }

  const onPagesSet = (pagedList, pageNum) => {
    setPagedList(pagedList)
    setPageNum(pageNum)
  }

  // const removeOrderFromList = (selectedOrder) => {
  //   if (selectedOrder.accountType == 14) {
  //     return
  //   }
  //   let orders = ordersWithStatuses.filter(
  //     (order) => order.orderNumber != selectedOrder?.orderNumber
  //   )
  //   setOrdersWithStatuses(orders)
  // }

  const serchForOrder = () => {
    let duplicatePackageNumber = false
    const order = ordersWithStatuses.find((item) => {
      const searchTermLower = searchTerm.toLowerCase()
      const packageNumberMatch =
        item?.packageNumber?.toLowerCase() == searchTermLower
      const orderNumberMatch = item?.orderNumber == searchTermLower
      if (packageNumberMatch) {
        duplicatePackageNumber = ordersWithStatuses.some(
          (otherItem) =>
            otherItem !== item &&
            otherItem?.packageNumber?.toLowerCase() === searchTermLower
        )
      }

      return packageNumberMatch || orderNumberMatch
    })

    if (order && order.accountType == 14) {
      return "cheapsim"
    }

    if (duplicatePackageNumber) {
      return "duplicate"
    }
    if (
      order?.orderType == 1 &&
      order?.orderStatus == 17 &&
      isMultipleBags(order)
    ) {
      return "multipleBags"
    }
    return order
  }

  const isMultipleBags = (order) => {
    let countHanging = 0
    let countSack = 0
    let countFolded = 0
    if (order?.orderItems) {
      for (let item of order?.orderItems) {
        if (
          item["processName"] == "ironing" ||
          item["processName"] == "laundry ironing" ||
          item["processName"] == "dry cleaning"
        ) {
          countHanging += 1
        }
        if (item["processName"] == "laundry") {
          countSack = order.bagsQuantity
        }
        if (
          item["processName"] == "ironing and package" ||
          item["processName"] == "laundry ironing package"
        ) {
          countFolded += 1
        }
      }
    }

    const countTotal = (countHanging > 0) + (countSack > 0) + (countFolded > 0)
    if (countTotal >= 2) {
      setOrderForEdit(order)
      return true
    }
  }

  const getTitle = (order) => {
    if (order.orderType == 1 && order.orderStatus == 1) {
      return order.serviceSupplierName
    }
    if (order.orderType == 1 && order.orderStatus == 3) {
      return order.routeName
    }
    if (order.orderType != 1 && order.orderStatus == 16) {
      if (order.orderType == 8 || order.orderType == 31) {
        return getValEnumFromIndex(props.accountTypes, order.accountType)
      }
      return getValEnumFromIndex(props.orderTypes, order.orderType)
    }
    if (order.orderType != 1 && order.orderStatus == 3) {
      return order.routeName
    }
  }

  const closePopup = () => {
    setIsUpdateErrorMsg(false)
    setSearchTerm("")
    setDuplicateMsg(false)
    setCheapSimMsg(false)
    setMultipleBagsMsg(false)
    setSendSmsConfirmMsg(false)
    setSendSmsDelaySuccessMsg(false)
    setNotFoundMsg(false)
    setIsNotFoundMsgWithSearch(false)
    setIsEndOfDayConfirmMsg(false)
    orderPackageRef.current.focus()
  }

  const onEditClick = (orderId) => {
    window.open(
      window.location.origin + "/OrderEditExternalScreen?orderId=" + orderId,
      "_blank"
    )
  }

  const openCheapSimPage = () => {
    setCheapSimMsg(false)
    props.history.push("/CheapSimPage")
  }

  const confirmMultipleBagsOrderUpdate = () => {
    props.processReceptionOrder(orderForEdit.orderId)
    closePopup()
  }

  const redirectToSearchPage = () => {
    props.history.push("/OrdersSearchScreen?searchTerm=" + searchTerm)
  }

  const sendSmsToAllRemainingOrders = () => {
    setSendSmsConfirmMsg(false)
    let ordersListids = []
    for (let order of ordersWithStatuses) {
      if (order.orderType == 1) {
        ordersListids.push(order.orderId)
      }
    }
    props.sendListDelaySMS(ordersListids)
  }

  const clearShowNotes = () => {
    setIsShowNotes(false)
  }

  const sendEndOfDay = () => {
    setIsEndOfDayConfirmMsg(false)
    let orders = ordersWithStatuses.map((order) => ({
      packageNumber: order.packageNumber,
      orderNumber: order.orderNumber,
      orderType: order.orderType,
      accountType: order.accountType,
      orderConfirmDate: order.orderConfirmDate,
    }))
    props.sendEndOfDayNotification(orders)
  }

  const renderAcceptedOrderData = () => {
    return (
      <div
        className={`accepted-order ${isFadeVisible ? "fade-in" : ""}`}
        style={{
          "--border-color":
            acceptedOrder.orderStatus == 3 ? acceptedOrder?.routeColor : null,
        }}
      >
        <div className="destination">{getTitle(acceptedOrder)}</div>
        <div className="order-params">
          <div className="params-col">
            <div>מספר הזמנה: {acceptedOrder.orderNumber}</div>
            <div>מספר חבילה: {acceptedOrder.packageNumber}</div>
          </div>
          <hr />
          <div className="params-col">
            <div>
              סוג הזמנה:{" "}
              {getValEnumFromIndex(props.orderTypes, acceptedOrder.orderType)}
            </div>
            <div>
              סוג החשבון:{" "}
              {getValEnumFromIndex(
                props.accountTypes,
                acceptedOrder.accountType
              )}
            </div>
          </div>
          <hr />
          <div className="params-col">
            <div>
              עמדה:{" "}
              {(acceptedOrder.branchName
                ? acceptedOrder.branchName
                : acceptedOrder.station?.branch?.branchName) +
                " - " +
                (acceptedOrder.stationNumber
                  ? acceptedOrder.stationNumber
                  : acceptedOrder.station?.stationNumber)}
            </div>
            <div>
              סטטוס :{" "}
              {getStatusNameFromObj(
                props.orderStatusesObj,
                acceptedOrder.orderStatus
              )}
            </div>
          </div>
          <hr />
          <div className="params-col">
            <div>
              שם לקוח:{" "}
              {(acceptedOrder.firstName
                ? acceptedOrder.firstName
                : acceptedOrder.customer?.firstName) +
                " " +
                (acceptedOrder.lastName
                  ? acceptedOrder.lastName
                  : acceptedOrder.customer?.lastName)}
            </div>
            {acceptedOrder.orderStatus == 3 ? (
              <div>קוד איזור: {acceptedOrder?.routeAreaCode}</div>
            ) : null}
          </div>
        </div>

        <div className="count">{ordersCount}</div>
      </div>
    )
  }

  return (
    <section className="generalReceptionPage">
      <div className="header">
        <div className="sub-header">
          <div className="order-input">
            <input
              ref={orderPackageRef}
              disabled={!selectedAreaCode}
              placeholder="מספר חבילה"
              value={searchTerm}
              className="input-text"
              onChange={(e) => setSearchTerm(e.target.value)}
            ></input>
            <BlueButton
              extraClass="btn-reset-table"
              onClick={(e) => props.getReceptionOrders()}
              disabled={props.isLoading}
            >
              רענן טבלה
            </BlueButton>
          </div>
          {props.logedUserData?.role === "WHOperator" ? null : (
            <div className="btn-wrapper">
              <BlueButton
                extraClass="btn-regular"
                onClick={(e) => setGameStorm(!isShowGameStorm)}
              >
                {isShowGameStorm ? "הסתר GameStorm" : "הצג GameStorm"}
              </BlueButton>
              <BlueButton
                extraClass="btn-delay"
                onClick={(e) => setSendSmsConfirmMsg(true)}
              >
                שלח הודעות עיכוב
              </BlueButton>
              <BlueButton
                extraClass="btn-regular btn-end-day"
                onClick={() => setIsEndOfDayConfirmMsg(true)}
              >
                סיום סריקה
              </BlueButton>
            </div>
          )}
          <Select
            ref={selectRef}
            className="area-code-select"
            value={selectedAreaCode}
            placeholder="בחר קוד איזור"
            options={formatedRouteAreacCodes}
            onFocus={() => setIsSelectFocused(true)}
            onBlur={() => setIsSelectFocused(false)}
            onChange={(e) => {
              setSelectedAreaCode(e)
              setIsSelectFocused(false)
            }}
            onMenuClose={() => orderPackageRef.current.focus()}
          />
        </div>
        {acceptedOrder ? renderAcceptedOrderData() : null}
      </div>
      {selectedAreaCode ? (
        <div>
          <ReceptionOrdersList
            items={pagedList}
            onEditClick={onEditClick}
            isLoading={props.isLoading}
            role={props.logedUserData?.role}
          />
          <Pagination
            fullList={ordersWithStatuses}
            totalPerPage={30}
            onSubListReady={(pagedList, pageNum) =>
              onPagesSet(pagedList, pageNum)
            }
            pageNum={pageNum}
          />
        </div>
      ) : null}
      <NotificationModal
        show={isUpdateErrorMsg}
        title="כישלון"
        type="error"
        text="הזמנה לא עודכנה"
        onOkClick={() => closePopup()}
      />
      <NotificationModal
        show={isSendSmsDelaySuccessMsg}
        title="בהצלחה"
        text="הודעות נשלחו בהצלחה"
        onOkClick={() => closePopup()}
      />
      <NotificationModal
        show={isDuplicateMsg}
        title="כפילות"
        text={`יש יותר מי אחד תוצאות למספר חבילה ${searchTerm}`}
        onOkClick={() => closePopup()}
      />
      <NotificationModal
        show={isNotFoundMsg}
        type="warning"
        title="כישלון"
        text="הזמנה לא נמצאה"
        onOkClick={() => closePopup()}
      />
      <NotificationModal
        show={props.endOfDayReportSuccess}
        title="הצלחה"
        text="דוח הזמנות לא סרוקות נשלח בהצלחה, יום טוב."
        onOkClick={() => props.clearEndOfDay()}
      />
      <NotificationModal
        show={props.endOfDayReportFail}
        type="error"
        title="כישלון"
        text="דוח הזמנות לא נשלח, אנא פנה למנהל."
        onOkClick={() => props.clearEndOfDay()}
      />

      <NotificationModal
        show={isShowNotes}
        type="warning"
        title="קיימות הערות להזמנה"
        text={notesList}
        onOkClick={() => clearShowNotes()}
      />
      <NotificationModal
        show={props.isNotAllowedToProcess}
        type="warning"
        title=""
        text={"לא ניתן לעדכן הזמנה זו"}
        onOkClick={() => props.clearNotAllowedToProcess()}
      />
      <YesNoPopup
        isOpen={isNotFoundMsgWithSearch}
        infoMsg={
          `הזמנה מספר ${searchTerm} לא נמצאה.` +
          "\n" +
          "רוצה לעבור לעמוד חיפוש?"
        }
        close={() => closePopup()}
        confirm={() => redirectToSearchPage()}
      />
      <YesNoPopup
        isOpen={isCheapSimMsg}
        infoMsg={`יש לעבור למסך סריקה CheapSim`}
        close={() => closePopup()}
        confirm={() => openCheapSimPage()}
      />
      <YesNoPopup
        isOpen={isMultipleBagsMsg}
        infoMsg={"להזמנה זו יש יותר מי שק אחד. האם להמשיך?"}
        close={() => closePopup()}
        confirm={() => confirmMultipleBagsOrderUpdate()}
      />
      <YesNoPopup
        isOpen={isSendSmsConfirmMsg}
        infoMsg={"האם לשלוח הודעת עיכוב לכל ההזמנות הנותרות?"}
        close={() => closePopup()}
        confirm={() => sendSmsToAllRemainingOrders()}
      />
      <YesNoPopup
        isOpen={isEndOfDayConfirmMsg}
        infoMsg={"האם לסיים את סריקות להיום?"}
        close={() => closePopup()}
        confirm={() => sendEndOfDay()}
      />
      <YesNoPopup
        isOpen={isShowRouteCodeValidation}
        infoMsg={
          <div>
            <div>חבילה שיכת לקוד איזור אחר ({currentOrderAreaCode}).</div>
            <div>האם להמשיך?</div>
          </div>
        }
        close={() => setIsShowRouteCodeValidation(false)}
        confirm={() => {
          props.processReceptionOrder(orderForEdit.orderId)
          setIsShowRouteCodeValidation(false)
        }}
      />
    </section>
  )
}

const mapStateToProps = ({
  ordersList,
  receptionOrders,
  ordersStatuses,
  selectOptions,
  login,
  routeAreaCode,
}) => {
  const {
    ordersListArr,
    sendSmsSuccess,
    orderByPackageOrNumber,
    isOrderByPackageOrNumberSuccess,
    isOrderByPackageOrNumberFail,
  } = ordersList
  const {
    receptionOrdersList,
    currentSearchedPageStep,
    currentSearchedPage,
    updatedReceptionOrder,
    receptionOrderProcessSuccess,
    receptionOrderProcessFail,
    endOfDayReportSuccess,
    endOfDayReportFail,
    isNotAllowedToProcess,
    isLoading,
  } = receptionOrders
  const { ordersStatusesArr, orderStatusesObj } = ordersStatuses
  const { orderTypes, accountTypes } = selectOptions
  const { logedUserData } = login
  const { routeAreaCodes } = routeAreaCode
  return {
    ordersListArr,
    receptionOrdersList,
    orderTypes,
    ordersStatusesArr,
    orderStatusesObj,
    currentSearchedPageStep,
    currentSearchedPage,
    updatedReceptionOrder,
    receptionOrderProcessSuccess,
    receptionOrderProcessFail,
    accountTypes,
    isLoading,
    sendSmsSuccess,
    orderByPackageOrNumber,
    isOrderByPackageOrNumberSuccess,
    isOrderByPackageOrNumberFail,
    endOfDayReportSuccess,
    endOfDayReportFail,
    isNotAllowedToProcess,
    logedUserData,
    routeAreaCodes,
  }
}

export default connect(mapStateToProps, {
  fetchAllOrders,
  fetchAccountTypes,
  setExternalOrderToEdit,
  clearOrderDetails,
  getReceptionOrders,
  fetchOrderTypes,
  fetchAllOrderStatuses,
  processReceptionOrder,
  clearReceptionOrders,
  printWhiteReceipt,
  sendListDelaySMS,
  clearReadyInLockerSMS,
  getOrderByPackageOrNumber,
  clearOrderByPackageOrNumber,
  sendEndOfDayNotification,
  clearEndOfDay,
  clearNotAllowedToProcess,
  getAllRouteAreaCodes,
})(GeneralReceptionPage)
