import {
  GET_STORAGE_SACK_NUMBERS_SUCSESS,
  GET_STORAGE_SACK_NUMBERS_FAIL,
  ON_CLOSE_STORAGE_SACK_REQUEST,
  ON_CLOSE_STORAGE_SACK_SUCCESS,
  CLEAR_CLOSE_STORAGE_SACK,
  ON_CLOSE_STORAGE_SACK_FAIL,
  ON_ORDER_IN_SACK_SUCCESS,
  ON_ORDER_IN_SACK_FAIL,
} from "../actions/types"

import {} from "../utils/EnumsAndConsts"

const INIT_STATE = {
  isloading: false,
  storageSackNumbers: [],
  storageSackClosedSuccess: false,
  storageSackClosedFail: false,
  ordersInSackList: [],
  getOrdersInSackSuccess: false,
  getOrdersInSackFail: false,
  storageSack: {},
}

const OrderStorageSackReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STORAGE_SACK_NUMBERS_SUCSESS:
      return {
        ...state,
        storageSackNumbers: action.payload,
      }

    case GET_STORAGE_SACK_NUMBERS_FAIL:
      return {
        ...state,
        storageSackNumbers: [],
      }

    case ON_CLOSE_STORAGE_SACK_REQUEST:
      return {
        ...state,
        isloading: true,
        storageSackClosedSuccess: false,
        storageSackClosedFail: false,
      }

    case ON_CLOSE_STORAGE_SACK_SUCCESS:
      return {
        ...state,
        isloading: false,
        storageSack: action.payload,
        storageSackClosedSuccess: true,
        storageSackClosedFail: false,
      }
    case CLEAR_CLOSE_STORAGE_SACK:
      return {
        ...state,
        isloading: false,
        storageSack: {},
        storageSackClosedSuccess: false,
        storageSackClosedFail: false,
      }

    case ON_CLOSE_STORAGE_SACK_FAIL:
      return {
        ...state,
        isloading: false,
        storageSackClosedSuccess: false,
        storageSackClosedFail: true,
      }
    case ON_ORDER_IN_SACK_SUCCESS:
      return {
        ...state,
        ordersInSackList: action.payload,
        getOrdersInSackSuccess: true,
        getOrdersInSackFail: false,
      }

    case ON_ORDER_IN_SACK_FAIL:
      return {
        ...state,
        getOrdersInSackSuccess: false,
        getOrdersInSackFail: true,
      }
    default:
      return state
  }
}

export default OrderStorageSackReducer
