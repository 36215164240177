import {
  ON_GET_STATIONS_SUCCESS,
  ON_GET_STATIONS_FAILURE,
  ON_SELECT_STATION,
  ON_CLOSE_STATION_FORM,
  ON_LEAVE_STATIONS_SECTION,
  ON_EDIT_STATION,
  ON_UPDATE_STATION_SUCCESS,
  ON_UPDATE_STATION_FAILURE,
  ON_CREATE_STATION_SUCCESS,
  ON_CREATE_STATION_FAILURE,
  ON_READ_LOCKERS_LAYOUT_SUCCESS,
  ON_EDIT_LOCKERS,
  ON_CLOSE_STATION_NOTIFICATION_SUCCESSED,
  ON_CLOSE_STATION_NOTIFICATION_FAILTURE,
  ON_STATION_SHOW_LOADING,
  SEND_STATION_SMS_SUCCESS,
  SEND_STATION_SMS_FAIL,
  CLEAR_STATION_SMS,
  ON_STATIONS_FILTER_BY_TEXT,
  ON_STATIONS_FILTER_BY_STATION,
  ON_STATIONS_SET_PAGE,
  CLEAR_LOCAL_STATIONS_FILTER_SEARCH,
  GET_STATION_BY_ID_SUCCESS,
  GET_STATION_BY_ID_FAIL,
  GET_POSTAL_CODE_SUCCESS,
  GET_POSTAL_CODE_FAIL,
  ON_CLOSE_POSTAL_NOTIFICATION_ERROR,
  GET_POSTAL_CODE_INVALID,
  ON_CLOSE_POSTAL_NOTIFICATION_INVALID,
  ON_STATIONS_FILTER_BY_PAYMENT_VERSION,
  ON_STATIONS_FILTER_BY_IS_ALIVE,
  ON_STATIONS_FILTER_BY_IS_VISIBLE,
  ON_STATIONS_FILTER_BY_ROUTE,
  ON_STATIONS_FILTER_BY_AREA_CODE,
} from "./../actions/types"

import {
  isProdEnv,
  handleFilteredActiveStatusesUpdate,
  handleFilteredActiveTypesUpdate,
  stations_RecalcFilteredListOutOfLatestState,
  stations_HandleFilteredActiveStationsUpdate,
  hanldeFilterByIsPaidUpdate,
} from "../utils"

const emptyStation = () => {
  return {
    stationId: "",
    stationNumber: "",
    branchId: "",
    branchName: "",
    branchAddress: "",
    branchName_en: "",
    branchAddress_en: "",
    branchDisplayAddress: "",
    branchDisplayName: "",
    barStationNumber: "",
    isAlive: "",
    isDeliveryActive: "",
    isLaundryActive: "",
    isLockerRentActive: "",
    isLockerToLockerDeliveryActive: "",
    isDecathlon: "",
    minimumLaundryEmptyLockers: "",
    priceListId: "",
    layoutFile: null,
    latitude: "",
    longitude: "",
    availabilityHours: "",
    isDeliveryCompaniesActive: null,
    stationPaymentVersionId: "",
    stationPriority: "",
    routeId: "",
    routeName: "",
    cityId: "",
    isAmot: "",
    isBLE: "",
    isYadMordechai: "",
    isSdeMoshe: "",
    isAmirim: "",
    isOutdoor: true,
    isVisible: "",
    nayaxTerminalSN: "",
    stationCodeBLE: "",
    postalCode: "",
    isReturnPackageActive: "",
    videoUrl: "",
    isReturnExelotActive: "",
    dhlStationNumber: "",
    dhlRouteCode: "",
    isApple: "",
    isTAU: "",
    isBerorHayil: "",
    isAmshat: "",
  }
}

const INIT_STATE = {
  stations: [],
  station: emptyStation(),
  stationLockers: [],
  showNotification_Success: false,
  showNotification_Faiture: false,
  isLoading: false,
  isStationSmsFail: false,
  isStationSmsSuccess: false,
  showPostalCodeError: false,
  showPostalCodeInvalid: false,
  stationsLocalSearch: {
    searchText: "",
    searchPaymentVersion: "",
    isAliveStatus: 0,
    isVisibleStatus: 0,
    stationsFilteredListArr: [],
    filtererActiveStations: [],
    routeId: null,
    areaCodeId: null,
    page: 1,
  },
}

const StationsSectionReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_GET_STATIONS_SUCCESS:
      return stations_RecalcFilteredListOutOfLatestState({
        ...state,
        stations: action.payload,
        isLoading: false,
      })
    case ON_GET_STATIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    case ON_LEAVE_STATIONS_SECTION:
      return {
        ...state,
        stations: [],
        station: emptyStation(),
        stationLockers: [],
        showNotification_Success: false,
        showNotification_Faiture: false,
        isLoading: false,
      }
    case ON_SELECT_STATION:
      return {
        ...state,
        station: action.payload,
        stationLockers: action.payload.stationLockers,
      }
    case ON_CLOSE_STATION_FORM:
      return {
        ...state,
        station: emptyStation(),
        stationLockers: [],
      }
    case ON_EDIT_STATION:
      return {
        ...state,
        station: action.payload,
      }
    case ON_READ_LOCKERS_LAYOUT_SUCCESS:
      return {
        ...state,
        stationLockers: action.payload,
      }
    case ON_EDIT_LOCKERS:
      return {
        ...state,
        stationLockers: action.payload,
      }
    case ON_UPDATE_STATION_SUCCESS:
      return {
        ...state,
        station: emptyStation(),
        stationLockers: [],
        showNotification_Success: true,
        showNotification_Faiture: false,
        isLoading: false,
      }
    case ON_UPDATE_STATION_FAILURE:
      return {
        ...state,
        showNotification_Success: false,
        showNotification_Faiture: true,
        isLoading: false,
      }
    case ON_CREATE_STATION_SUCCESS:
      return {
        ...state,
        station: emptyStation(),
        stationLockers: [],
        showNotification_Success: true,
        showNotification_Faiture: false,
        isLoading: false,
      }
    case ON_CREATE_STATION_FAILURE:
      return {
        ...state,
        showNotification_Success: false,
        showNotification_Faiture: true,
        isLoading: false,
      }
    case ON_CLOSE_STATION_NOTIFICATION_SUCCESSED:
      return {
        ...state,
        showNotification_Success: false,
      }
    case ON_CLOSE_STATION_NOTIFICATION_FAILTURE:
      return {
        ...state,
        showNotification_Faiture: false,
      }
    case ON_STATION_SHOW_LOADING:
      return {
        ...state,
        isLoading: true,
      }

    case SEND_STATION_SMS_SUCCESS:
      return {
        ...state,
        isStationSmsSuccess: true,
        isStationSmsFail: false,
      }
    case SEND_STATION_SMS_FAIL:
      return {
        ...state,
        isStationSmsFail: true,
        isStationSmsSuccess: false,
      }
    case CLEAR_STATION_SMS:
      return {
        ...state,
        isStationSmsFail: false,
        isStationSmsSuccess: false,
      }

    //SEARCH
    case ON_STATIONS_FILTER_BY_PAYMENT_VERSION:
      return stations_RecalcFilteredListOutOfLatestState({
        ...state,
        stationsLocalSearch: {
          ...state.stationsLocalSearch,
          searchPaymentVersion: action.payload,
          page: 1,
        },
        isLoading: false,
      })
    case ON_STATIONS_FILTER_BY_IS_ALIVE:
      return stations_RecalcFilteredListOutOfLatestState({
        ...state,
        stationsLocalSearch: {
          ...state.stationsLocalSearch,
          isAliveStatus: action.payload,
          page: 1,
        },
        isLoading: false,
      })
    case ON_STATIONS_FILTER_BY_IS_VISIBLE:
      return stations_RecalcFilteredListOutOfLatestState({
        ...state,
        stationsLocalSearch: {
          ...state.stationsLocalSearch,
          isVisibleStatus: action.payload,
          page: 1,
        },
        isLoading: false,
      })
    case ON_STATIONS_FILTER_BY_ROUTE:
      return stations_RecalcFilteredListOutOfLatestState({
        ...state,
        stationsLocalSearch: {
          ...state.stationsLocalSearch,
          routeId: action.payload,
          page: 1,
        },
        isLoading: false,
      })
    case ON_STATIONS_FILTER_BY_AREA_CODE:
      return stations_RecalcFilteredListOutOfLatestState({
        ...state,
        stationsLocalSearch: {
          ...state.stationsLocalSearch,
          areaCodeId: action.payload,
          page: 1,
        },
        isLoading: false,
      })
    case ON_STATIONS_FILTER_BY_TEXT:
      return stations_RecalcFilteredListOutOfLatestState({
        ...state,
        stationsLocalSearch: {
          ...state.stationsLocalSearch,
          searchText: action.payload,
          page: 1,
        },
        isLoading: false,
      })
    case ON_STATIONS_SET_PAGE:
      return stations_RecalcFilteredListOutOfLatestState({
        ...state,
        stationsLocalSearch: {
          ...state.stationsLocalSearch,
          page: action.payload,
        },
        isLoading: false,
      })
    case ON_STATIONS_FILTER_BY_STATION:
      return stations_HandleFilteredActiveStationsUpdate(state, action.payload)
    case CLEAR_LOCAL_STATIONS_FILTER_SEARCH:
      return stations_RecalcFilteredListOutOfLatestState({
        ...state,
        stationsLocalSearch: {
          searchText: "",
          stationsFilteredListArr: [],
          filtererActiveStations: [],
          page: state.stationsLocalSearch.page,
          routeId: null,
          areaCodeId: null,
        },
        isLoading: false,
      })
    case GET_STATION_BY_ID_SUCCESS:
      return {
        ...state,
        station: action.payload,
        stationLockers: action.payload.stationLockers,
        isLoading: false,
      }
    case GET_STATION_BY_ID_FAIL:
      return {
        ...state,
        station: emptyStation(),
        stationLockers: [],
        isLoading: false,
      }
    case GET_POSTAL_CODE_SUCCESS:
      return {
        ...state,
        station: {
          ...state.station,
          postalCode: action.payload,
        },
      }
    case GET_POSTAL_CODE_FAIL:
      return {
        ...state,
        showPostalCodeError: true,
      }
    case GET_POSTAL_CODE_INVALID:
      return {
        ...state,
        showPostalCodeInvalid: true,
      }

    case ON_CLOSE_POSTAL_NOTIFICATION_ERROR:
      return {
        ...state,
        showPostalCodeError: false,
      }
    case ON_CLOSE_POSTAL_NOTIFICATION_INVALID:
      return {
        ...state,
        showPostalCodeInvalid: false,
      }

    default:
      return state
  }
}

export default StationsSectionReducer
