import React, { Component } from "react"
import { connect } from "react-redux"
import Manager from "./Manager/Manager.js"
import WHOperator from "./WHOperator/WHOperator.js"
import Driver from "./Driver/Driver.js"
import "./style.scss"
import Cookies from "js-cookie"
import NotAliveStations from "./NotAliveStations"
import StationsVoltage from "./StationsVoltage"
import CheapSim from "./CheapSim/CheapSim.js"
import ScanSackMobile from "./DeliveryOrderSackMobile/ScanSackMobile.js"
import DeliverySackMenu from "./DeliveryOrderSack/DeliverySackMenu"
import {
  getDownStations,
  getStationsVoltage,
  getStationsVoltagePerStation,
} from "../../../actions"
import "./style.scss"
import GeneralReception from "./GeneralReception/GeneralReception.js"
import ShippingCertificate from "./ShippingCertificate/ShippingCertificate.js"
import CloseOrdersSack from "./CloseOrdersSack/CloseOrdersSack.js"
import ScanOrderMobile from "./ScanOrderMobile/ScanOrderMobile.js"

class HomeScreen extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      loadDownStationsInterval: null,
      loadStationsVoltageInterval: null,
      defaultValueVoltageSelect: { value: 0, label: "כול העמדות" },
    }

    this.state = this.INIT_STATE
  }

  /*
  const intervalRef = useRef(null)

const incrementCounter = () => {
  intervalRef.current = setInterval(() => {
    setCounter(prevState => prevState + 1)
  }, 1000);
};

const resetCounter = () => {
  clearInterval(intervalRef.current);
  intervalRef.current = null;
};
  
  */

  componentWillMount() {
    const { unauthorized, history } = this.props
    if (unauthorized) {
      history.push("/")
    }
    this.props.getDownStations()
    this.props.getStationsVoltage()
    const loadDownStationsInterval = setInterval(() => {
      this.props.getDownStations()
    }, 60000)
    this.setState({ loadDownStationsInterval })

    const loadStationsVoltageInterval = setInterval(() => {
      this.loadStationsVoltage()
    }, 60000)
    this.setState({ loadStationsVoltageInterval })
  }

  loadStationsVoltage() {
    const { defaultValueVoltageSelect } = this.state
    if (defaultValueVoltageSelect.value === 0) {
      this.props.getStationsVoltage()
    } else {
      this.props.getStationsVoltagePerStation(
        defaultValueVoltageSelect.stationId
      )
    }
  }

  componentWillUnmount() {
    const { loadDownStationsInterval, loadStationsVoltageInterval } = this.state
    clearInterval(loadDownStationsInterval)
    clearInterval(loadStationsVoltageInterval)
    this.setState({
      loadDownStationsInterval: null,
      loadStationsVoltageInterval: null,
    })
  }

  componentWillReceiveProps(newProps) {
    const { unauthorized, history } = newProps
    if (unauthorized) {
      history.push("/")
    }
  }

  onSelectStationOnVoltage(item) {
    this.setState({ defaultValueVoltageSelect: item })

    if (item.value === 0) {
      this.props.getStationsVoltage()
      console.log(`getStationsVoltage`)
    } else {
      this.props.getStationsVoltagePerStation(item.stationId)
      console.log(`getStationsVoltagePerStation ${item.stationNumber}`)
    }
  }

  switchRole(role) {
    switch (role) {
      case "Manager":
        return (
          <div>
            <Manager history={this.props.history} />
            <div className="separator"></div>
            <div className={"wh-operator-wrapper"}>
              <WHOperator history={this.props.history} />
            </div>
            <div className="delivery-btns-holder">
              <DeliverySackMenu history={this.props.history} />
              <ScanSackMobile history={this.props.history} />
              <ScanOrderMobile history={this.props.history} />
              <CheapSim history={this.props.history} />
              <GeneralReception history={this.props.history} />
              <ShippingCertificate history={this.props.history} />
              <CloseOrdersSack history={this.props.history} />
            </div>

            <div className="separator"></div>

            <NotAliveStations history={this.props.history} />
            <StationsVoltage
              history={this.props.history}
              defaultValueVoltageSelect={this.state.defaultValueVoltageSelect}
              onSelectStationOnVoltage={(item) =>
                this.onSelectStationOnVoltage(item)
              }
            />
            <Driver history={this.props.history} />
          </div>
        )

      case "WHOperator":
        return <WHOperator history={this.props.history} />
      default:
        break
    }
  }

  render() {
    const { firstName, lastName, role } = this.props.logedUserData || {}
    const cookieFirstName = Cookies.get("firstName")
    const cookieLastName = Cookies.get("lastName")

    return (
      <section className="homePage">
        <h1 className="welcome-title">
          היי&nbsp;
          {firstName
            ? firstName + " " + lastName
            : cookieFirstName + " " + cookieLastName}
          !
        </h1>
        {this.switchRole(role ? role : Cookies.get("role"))}
      </section>
    )
  }
}

const mapStateToProps = ({ login, errors }) => {
  const { logedUserData } = login
  const { unauthorized } = errors
  return {
    logedUserData,
    unauthorized,
  }
}

export default connect(mapStateToProps, {
  getDownStations,
  getStationsVoltage,
  getStationsVoltagePerStation,
})(HomeScreen)
