import React, { Component } from "react"

import { Provider } from "react-redux"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

import Store from "./store"

import AppDecorator from "./components/AppDecorator"
import LoginScreen from "./components/screens/LoginScreen"
import OrdersListScreen from "./components/screens/OrdersListScreen"
import OrdersSearchScreen from "./components/screens/OrdersSearchScreen"
import OrdersEditingScreen from "./components/screens/OrdersEditingScreen"
import UsersListScreen from "./components/screens/UsersListScreen"
import PriceListsScreen from "./components/screens/PriceListsScreen"
import StationsScreen from "./components/screens/StationsScreen"
import LockersScreen from "./components/screens/LockersScreen"
import ExportScreen from "./components/screens/ExportScreen"
import ImportScreen from "./components/screens/ImportScreen"
import ReportsScreen from "./components/screens/ReportsScreen"
import EditorScreen from "./components/screens/EditorScreen"
import OrderTicket from "./components/screens/OrderTicket"
// import RealTimeDashboard from "./components/screens/RealTimeDashboard";
import UserCreateEditScreen from "./components/screens/UserCreateEditScreen"
import StationsNotifications from "./components/screens/StationsScreen/StationsNotifications"
import CouponsScreen from "./components/screens/CouponsScreen"
import ServiceSuppliersScreen from "./components/screens/ServiceSuppliersScreen"
import HomeScreen from "./components/screens/HomeScreen"
import ScanOrders from "./components/screens/HomeScreen/WHOperator/ScanOrders"
import CitiesScreen from "./components/screens/CitiesScreen"
import UserEditExternalScreen from "./components/screens/UserEditExternalScreen"
import DeliveryOrderSack from "./components/screens/HomeScreen/DeliveryOrderSack/OneSackPage"
import SacksScreen from "./components/screens/HomeScreen/DeliveryOrderSack/SacksScreen"
import CheapSimPage from "./components/screens/HomeScreen/CheapSim/CheapSimPage"
import GeneralReception from "./components/screens/HomeScreen/GeneralReception/GeneralReceptionPage"
import OrderEditExternalScreen from "./components/screens/OrderEditExternalScreen"
import ShippingCertificate from "./components/screens/HomeScreen/ShippingCertificate/ShippingCertificatePage"
import UpdatePasswordScreen from "./components/screens/UpdatePasswordScreen"
import ScanSackMobilePage from "./components/screens/HomeScreen/DeliveryOrderSackMobile/ScanSackMobilePage"
import CloseOrdersSackPage from "./components/screens/HomeScreen/CloseOrdersSack/CloseOrdersSackPage"
import ScanOrderMobilePage from "./components/screens/HomeScreen/ScanOrderMobile/ScanOrderMobilePage"
import "./App.css"
import { isProdEnv } from "./utils"
import { loginUser } from "./actions"
import axios from "axios"

class App extends Component {
  componentWillMount() {
    // ! AUTO SIGN IN FOR TESTING!
    // if (!isProdEnv()) {
    //     // Store.dispatch(loginUser('0000000002', '123456'));
    //     //Yulia token
    //     //const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImExZjRlODVkLTU5OTItNDQyYy1jOTA5LTA4ZDcwYWI5ODY4ZSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL21vYmlsZXBob25lIjoiMDAwMDAwMDAwMiIsImVtYWlsIjoiZ0B2LmNvLmlsIiwicm9sZSI6Ik1hbmFnZXIiLCJuYmYiOjE1NzAzNDg5NTYsImV4cCI6MTU3Mjk0MDk1NiwiaWF0IjoxNTcwMzQ4OTU2fQ.ZFWwRPwubf-5MRWYNzzcMLSFQNhiRIn1I_NDl8jW1Fc';
    //     const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImExZjRlODVkLTU5OTItNDQyYy1jOTA5LTA4ZDcwYWI5ODY4ZSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL21vYmlsZXBob25lIjoiMDAwMDAwMDAwMiIsImVtYWlsIjoiZ0B2LmNvLmlsIiwicm9sZSI6Ik1hbmFnZXIiLCJuYmYiOjE1NzA0MDMzNjIsImV4cCI6MTU3Mjk5NTM2MiwiaWF0IjoxNTcwNDAzMzYyfQ.Crtl_AhQ4DYMn0o4f7HRV16oki-ReajBoDH0PhKGsV8';
    //     axios.defaults.headers.common["Authorization"] = `Bearer ${token}`; //eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjZmOTE1NDI0LTk0NmMtNGZmMi0xMGNhLTA4ZDZmNTVhNGJlZSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL21vYmlsZXBob25lIjoiMDUyNDI0MDkzNCIsImVtYWlsIjoieXVsaWFnQGVmY29tLmNvLmlsIiwicm9sZSI6Ik1hbmFnZXIiLCJuYmYiOjE1NjEwMjAwOTAsImV4cCI6MTU2MTI3OTI5MCwiaWF0IjoxNTYxMDIwMDkwfQ.x2fDsgYvfhIafL4DnL-U6w7aqxWR4EzsIZ4CVSaL-VM';
    // }
  }
  render() {
    return (
      <Provider store={Store}>
        <Router>
          <AppDecorator>
            <Switch>
              {/* <Route path='/ThankYouScreen' component={ThankYouScreen} /> */}
              <Route path="/OrdersListScreen" component={OrdersListScreen} />
              <Route
                path="/OrdersSearchScreen"
                component={OrdersSearchScreen}
              />
              <Route
                path="/OrdersEditingScreen"
                component={OrdersEditingScreen}
              />
              <Route path="/UsersListScreen" component={UsersListScreen} />
              <Route path="/StationsScreen" component={StationsScreen} />
              <Route path="/LockersScreen" component={LockersScreen} />
              <Route path="/PriceListsScreen" component={PriceListsScreen} />
              <Route path="/ExportScreen" component={ExportScreen} />
              <Route
                path="/ServiceSuppliersScreen"
                component={ServiceSuppliersScreen}
              />
              <Route path="/ImportScreen" component={ImportScreen} />
              <Route path="/ReportsScreen" component={ReportsScreen} />
              {/* <Route path='/EditorScreen' component={EditorScreen} /> */}
              <Route path="/OrderTicket" component={OrderTicket} />
              {/* <Route path="/RealTimeDashboard" component={RealTimeDashboard} /> */}
              <Route
                path="/UserCreateEditScreen/:userId"
                component={UserCreateEditScreen}
              />
              <Route
                path="/UserEditExternalScreen"
                component={UserEditExternalScreen}
              />

              <Route
                path="/StationsScreen/notifications"
                component={StationsNotifications}
              />
              <Route path="/CouponsScreen" component={CouponsScreen} />
              <Route path="/HomeScreen" component={HomeScreen} />
              <Route path="/ScanOrders" component={ScanOrders} />
              <Route path="/CitiesScreen" component={CitiesScreen} />
              <Route path="/DeliveryOrderSack" component={DeliveryOrderSack} />
              <Route path="/SacksScreen/" component={SacksScreen} />
              <Route path="/CheapSimPage" component={CheapSimPage} />

              <Route
                path="/GeneralReceptionPage"
                component={GeneralReception}
              />
              <Route
                path="/ShippingCertificatePage"
                component={ShippingCertificate}
              />
              <Route
                path="/OrderEditExternalScreen"
                component={OrderEditExternalScreen}
              />
              <Route
                path="/UpdatePasswordScreen"
                component={UpdatePasswordScreen}
              />
              <Route
                path="/ScanSackMobilePage"
                component={ScanSackMobilePage}
              />
              <Route
                path="/CloseOrdersSackPage"
                component={CloseOrdersSackPage}
              />
              <Route
                path="/ScanOrderMobilePage"
                component={ScanOrderMobilePage}
              />
              <Route path="/" component={LoginScreen} />
            </Switch>
          </AppDecorator>
        </Router>
      </Provider>
    )
  }
}

export default App
